import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"

export default function LetterTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <SEO title={frontmatter.title} />
      <h1 className="phonemode letterhead"><span>{frontmatter.title}</span></h1>
      <div
        className="letter-content"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { path: { eq: $path } }) {
      html
      fields {
        path
      }
      frontmatter {
        title
      }
    }
  }
`
